import styled, { css } from "styled-components"
import { motion, MotionStyle } from "framer-motion"
import { useState, useEffect, useRef } from "react"
import { Plus, X } from "@phosphor-icons/react"

import FileSaver from "file-saver"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ValueVoter(props: {
    values: string[][]
    userColors: string[]
    locked: boolean
}) {
    const { values, userColors, locked } = props
    const [voteStore, setVoteStore] = useState(new VoteStore([], values))
    const [selected, setSelected] = useState("")
    const [nextUserColorIdx, setNextUserColorIdx] = useState(0)
    const [smallVersion, setSmallVersion] = useState(false)
    const [voteBarWidth, setVoteBarWidth] = useState(400)
    const containerRef = useRef(null)

    useEffect(() => {
        console.log("Got new values", values)
        setVoteStore(new VoteStore(voteStore.userInfos(), values))
    }, [values])

    useEffect(() => {
        if (locked) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            })
        } else {
            setVoteStore(new VoteStore([], values))
            setSelected("")
        }
    }, [locked])

    useEffect(() => {
        if (!containerRef.current) {
            return
        }
        const observer = new ResizeObserver((e) => {
            const containerWidth = e[0].contentRect.width

            if (containerWidth > 900) {
                setSmallVersion(false)
                setVoteBarWidth(440)
            } else if (containerWidth > 700) {
                setSmallVersion(false)
                setVoteBarWidth(340 + (containerWidth - 700) * 0.5)
            } else if (containerWidth > 500) {
                setSmallVersion(true)
                setVoteBarWidth(340 + (containerWidth - 500) * 0.5)
            } else {
                setSmallVersion(true)
                setVoteBarWidth(340)
            }
        })
        observer.observe(containerRef.current)
        return () => {
            observer.disconnect()
        }
    }, [containerRef])

    return (
        <motion.div ref={containerRef} style={{ backgroundColor: "#CED1FF" }}>
            <UserPicker
                userInfos={voteStore.userInfos()}
                selected={selected}
                selectUser={setSelected}
                nextColor={userColors[nextUserColorIdx]}
                addUser={(userName: string, color: string) => {
                    if (!voteStore.users().includes(userName)) {
                        setVoteStore(
                            voteStore.addUser(new UserInfo(userName, color))
                        )
                        setSelected(userName)
                        if (nextUserColorIdx < userColors.length - 1) {
                            setNextUserColorIdx(nextUserColorIdx + 1)
                        } else {
                            setNextUserColorIdx(0)
                        }
                    }
                }}
                deleteUser={(userName: string) => {
                    const users = voteStore.users()
                    if (users.includes(userName)) {
                        if (users.length == 1) {
                            // Deleted last user.
                            setSelected("")
                        } else if (userName == selected) {
                            setSelected(users.filter((u) => u != userName)[0])
                        }
                        setVoteStore(voteStore.deleteUser(userName))
                    }
                }}
            />
            <VoterContainer
                smallVersion={smallVersion}
                voteBarWidth={voteBarWidth}
            >
                {voteStore.votes(selected).map((vote, idx) => (
                    <>
                        <VoterColLeft
                            smallVersion={smallVersion}
                            voteBarWidth={voteBarWidth}
                            key={100 + idx}
                            style={{ gridRowStart: idx + 1 }}
                        >
                            {vote.left}
                        </VoterColLeft>
                        <VoterColRight
                            smallVersion={smallVersion}
                            voteBarWidth={voteBarWidth}
                            key={300 + idx}
                            style={{ gridRowStart: idx + 1 }}
                        >
                            {vote.right}
                        </VoterColRight>

                        <VoterColCenter
                            smallVersion={smallVersion}
                            key={200 + idx}
                            style={{ gridRowStart: idx + 1 }}
                        >
                            <ValueVoteBar
                                disabled={selected == ""}
                                value={vote.slider}
                                color={
                                    voteStore.color(selected) ||
                                    userColors[nextUserColorIdx]
                                }
                                roValues={voteStore.allVotesForValue(idx)}
                                onChange={(v: number) => {
                                    setVoteStore(
                                        voteStore.setVote(
                                            selected,
                                            idx,
                                            vote.set(v)
                                        )
                                    )
                                }}
                                width={voteBarWidth}
                            />
                        </VoterColCenter>
                    </>
                ))}
            </VoterContainer>
            <motion.button
                style={actionButtonStyle}
                onClick={() => {
                    if (selected == "" && voteStore.users().length > 0) {
                        setSelected(voteStore.users()[0])
                    } else {
                        setSelected("")
                    }
                }}
            >
                Compare
            </motion.button>
            <motion.button
                style={actionButtonStyle}
                onClick={() => {
                    getPDF(
                        voteStore,
                        selected == "" && voteStore.users().length > 0
                    )
                }}
            >
                {selected == "" && voteStore.users().length > 0
                    ? "Download Map"
                    : "Download Canvas"}
            </motion.button>
        </motion.div>
    )
}

ValueVoter.defaultProps = {
    // These are only used in Figma preview. To change actual values used
    // on the site, update value sets in ValueTable.tsx.
    values: [
        ["Generosity", "Accountability"],
        ["Play", "Work"],
        ["Youth", "Maturity"],
        ["Responsibility", "Spontaneity"],
        ["Seriousness", "Playfullness"],
        ["Art", "Science"],
    ],
    userColors: [
        "#FCF439",
        "#FC426B",
        "#FF8648",
        "#DE95FF",
        "#07DA87",
        "#FFDDCF",
        "#D3FFB5",
        "#95173B",
        "#F7FF9D",
    ],
    locked: false,
}

function UserPicker(props: {
    userInfos: UserInfo[]
    selected: string
    nextColor: string
    selectUser: (u: string) => void
    addUser: (u: string, color: string) => void
    deleteUser: (u: string) => void
}) {
    const { userInfos, selected, nextColor, selectUser, addUser, deleteUser } =
        props

    return (
        <motion.div style={userPickerStyle}>
            {userInfos.map((ui) => (
                <UserButton
                    key={ui.name}
                    text={ui.name}
                    color={ui.color}
                    selected={ui.name == selected}
                    onSelect={() => {
                        selectUser(ui.name)
                    }}
                    onDelete={() => {
                        deleteUser(ui.name)
                    }}
                />
            ))}
            <AddUserButton
                userInfos={userInfos}
                onAddUser={addUser}
                color={nextColor}
            />
        </motion.div>
    )
}

function AddUserButton(props: {
    userInfos: UserInfo[]
    color: string
    onAddUser: (u: string, color: string) => void
}) {
    const { userInfos, color, onAddUser } = props
    const [editing, setEditing] = useState(false)
    const [badInput, setBadInput] = useState(false)

    const badUsername = (u: string): boolean => {
        const trimmed = u.trim()
        if (trimmed == "") {
            return true
        }
        for (const ui of userInfos) {
            if (ui.name == trimmed) {
                return true
            }
        }

        return false
    }

    return editing ? (
        <UserButtonStyledDiv style={selectedUserButtonStyle}>
            <motion.button
                style={{
                    ...userButtonCircleStyle,
                    color: color,
                    boxShadow: "none",
                    background: "none",
                }}
                onClick={() => {
                    setEditing(false)
                }}
            >
                <UserButtonIconContainer>
                    <X />
                </UserButtonIconContainer>
            </motion.button>
            <form
                style={{ display: "flex" }}
                onSubmit={(event) => {
                    event.preventDefault()
                    const username =
                        event.currentTarget.elements.newUsername.value.trim()
                    if (!badUsername(username)) {
                        onAddUser(username, color)
                        setEditing(false)
                    }
                }}
            >
                <UserButtonStyledInput
                    style={userButtonInputStyle}
                    id="newUsername"
                    name="newUsername"
                    placeholder="User name"
                    onChange={(event) => {
                        setBadInput(badUsername(event.target.value))
                    }}
                    autoFocus
                ></UserButtonStyledInput>
                <motion.button
                    type="submit"
                    style={{ ...userButtonCircleStyle, backgroundColor: color }}
                >
                    <UserButtonIconContainer>
                        <Plus />
                    </UserButtonIconContainer>
                </motion.button>
            </form>
        </UserButtonStyledDiv>
    ) : (
        <UserButtonStyledButton
            style={userButtonStyle}
            onClick={() => {
                setEditing(true)
            }}
        >
            {userInfos.length == 0 ? (
                "Add User"
            ) : (
                <UserButtonIconContainer>
                    <Plus />
                </UserButtonIconContainer>
            )}
        </UserButtonStyledButton>
    )
}

function UserButton(props: {
    text: string
    color: string
    selected: boolean
    onSelect: () => void
    onDelete: () => void
}) {
    const { text, color, selected, onSelect, onDelete } = props

    return selected ? (
        <UserButtonStyledDiv style={selectedUserButtonStyle}>
            <motion.div style={userButtonSegmentStyle}>{text}</motion.div>
            <motion.button
                style={{ ...userButtonCircleStyle, backgroundColor: color }}
                onClick={onDelete}
            >
                <UserButtonIconContainer>
                    <X />
                </UserButtonIconContainer>
            </motion.button>
        </UserButtonStyledDiv>
    ) : (
        <UserButtonStyledButton onClick={onSelect} style={userButtonStyle}>
            <motion.div style={userButtonSegmentStyle}>{text}</motion.div>
            <motion.ellipse
                style={{ ...userButtonCircleStyle, backgroundColor: color }}
            />
        </UserButtonStyledButton>
    )
}

function ValueVoteBar(props: {
    value: number
    color: string
    roValues: VoteAndColor[]
    disabled: boolean
    onChange: (v: number) => void
    width: number
}) {
    const { value, color, disabled, roValues, onChange, width } = props
    const cirleOffset = 8
    const max = width - cirleOffset * 1.5
    const trackRef = useRef(null)

    const setValue = (coordX: number) => {
        if (disabled) {
            return
        }
        const bb = trackRef.current.getBoundingClientRect()
        let x = Math.max(0, Math.min(max, coordX - bb.x - cirleOffset))
        let newValue = Math.ceil((x / max) * 100)
        onChange(newValue)
    }

    return (
        <motion.div
            style={{
                position: "relative",
                width: width + "px",
            }}
            ref={trackRef}
            onTap={(event, info) => {
                setValue(info.point.x)
            }}
        >
            <ValueVoteLine />
            {disabled ? (
                roValues.map((value, idx) => (
                    <motion.ellipse
                        key={idx}
                        style={{
                            ...voteReadOnlyEllipseStyle,
                            top: 0.055 * width - 7.86 + "px",
                            backgroundColor: value.color,
                        }}
                        animate={{
                            x: max * (value.slider / 100),
                        }}
                    />
                ))
            ) : (
                <motion.ellipse
                    key={color}
                    style={{
                        ...voteEllipseStyle,
                        top: 0.04 * width - 9.34 + "px",
                        backgroundColor: color,
                    }}
                    drag="x"
                    animate={{
                        x: max * (value / 100),
                    }}
                    dragMomentum={false}
                    dragElastic={0}
                    dragConstraints={{
                        left: 0,
                        right: max,
                    }}
                    dragTransition={{ bounceStiffness: 0, bounceDamping: 0 }}
                    onDragEnd={(event, info) => {
                        setValue(info.point.x)
                    }}
                />
            )}
        </motion.div>
    )
}

class UserInfo {
    name: string
    color: string
    constructor(name: string, color: string) {
        this.name = name
        this.color = color
    }
}

class VoteStore {
    template: SingleVote[]
    userVotes: Map<UserInfo, SingleVote[]>

    constructor(users: UserInfo[], values: string[][]) {
        this.template = new Array()
        for (const v of values) {
            this.template.push(new SingleVote(v[0], v[1], 50))
        }
        this.userVotes = new Map<UserInfo, SingleVote[]>()
        for (const u of users) {
            this.addUser(u)
        }
    }
    addUser(user: UserInfo): VoteStore {
        let votes = new Array()
        for (const v of this.template) {
            const vv = v.clone()
            const randomInt = Math.floor(Math.random() * 5)
            vv.slider = 48 + randomInt
            votes.push(vv)
        }
        this.userVotes.set(user, votes)
        return Object.assign(Object.create(this), this)
    }
    userInfoForUsername(username: string): UserInfo {
        for (const u of this.userVotes.keys()) {
            if (u.name == username) {
                return u
            }
        }
    }
    deleteUser(user: string): VoteStore {
        if (user == "") {
            return
        }
        const ui = this.userInfoForUsername(user)
        if (ui) {
            this.userVotes.delete(ui)
        }
        return Object.assign(Object.create(this), this)
    }
    color(user: string): string {
        const ui = this.userInfoForUsername(user)
        if (ui) {
            return ui.color
        }
    }
    votes(user: string): SingleVote[] {
        const ui = this.userInfoForUsername(user)
        if (ui) {
            return this.userVotes.get(ui)
        }

        return this.template
    }
    setVote(user: string, idx: number, vote: SingleVote): VoteStore {
        const ui = this.userInfoForUsername(user)
        if (ui) {
            const votes = this.userVotes.get(ui)
            votes[idx] = vote
            this.userVotes.set(ui, votes)
        }

        // return a new copy of VoteStore to trigger React state update.
        return Object.assign(Object.create(this), this)
    }
    userInfos(): UserInfo[] {
        return Array.from(this.userVotes.keys())
    }
    users(): string[] {
        let names = new Array()
        for (let ui of this.userVotes.keys()) {
            names.push(ui.name)
        }
        return names
    }
    allVotesForValue(idx: number): VoteAndColor[] {
        let v = new Array()
        this.userVotes.forEach((values, ui) => {
            v.push(new VoteAndColor(values[idx].slider, ui.color))
        })
        return v
    }
}

class VoteAndColor {
    slider: number // 0-100
    color: string
    constructor(slider: number, color: string) {
        this.slider = slider
        this.color = color
    }
}

class SingleVote {
    left: string
    right: string
    slider: number // 0-100

    constructor(left: string, right: string, slider: number) {
        this.left = left
        this.right = right
        this.slider = slider
    }
    set(value: number): SingleVote {
        return new SingleVote(this.left, this.right, value)
    }
    clone(): SingleVote {
        return this.set(this.slider)
    }
}

async function getPDF(vs: VoteStore, drawVotes: boolean) {
    interface Value {
        left: string
        right: string
        votes: number[]
    }

    interface ValueJSON {
        values: Value[]
    }

    const values: ValueJSON = { values: [] }
    vs.template.forEach((v, idx) => {
        const val: Value = {
            left: v.left,
            right: v.right,
            votes: [],
        }
        const votes = vs.allVotesForValue(idx)
        if (drawVotes && votes.length > 0) {
            for (const marker of votes) {
                val.votes.push(marker.slider)
            }
        }
        values.values.push(val)
    })

    const fileName = drawVotes
        ? "ValueTension-map.pdf"
        : "ValueTension-template.pdf"

    return await fetch("https://bloom-pdf.w.ikkit.com/pdf/values/" + fileName, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
    })
        .then(function (response) {
            return response.blob()
        })
        .then(function (blob) {
            FileSaver.saveAs(blob, fileName)
        })
}

function ValueVoteLine() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 40"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M 6.627 15 L 6.627 31"
                fill="transparent"
                strokeWidth="2"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 54.819 17.5 L 54.819 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 103.313 17.5 L 103.313 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 151.807 17.5 L 151.807 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 200.602 15 L 200.602 31"
                fill="transparent"
                strokeWidth="2"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 248.795 17.5 L 248.795 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 297.289 17.5 L 297.289 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 345.783 17.5 L 345.783 28.5"
                fill="transparent"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 394.578 15 L 394.578 31"
                fill="transparent"
                strokeWidth="2"
                stroke="rgb(0,0,0)"
                strokeLinecap="square"
                strokeMiterlimit="10"
            ></path>
            <path
                d="M 393.976 24 L 6.024 24 L 6.024 22 L 393.976 22 Z"
                fill="rgb(0,0,0)"
            ></path>
        </svg>
    )
}

const userPickerStyle: MotionStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
}

const userButtonCSS = css`
    @media (min-width: 800px) {
        font-size: 18px
    }
    @media (min-width: 500px) {
        font-size: 16px
    }
    font-size: 14px

    font-family: Faktum Regular
    line-height: 1.4;
`

const UserButtonStyledButton = styled(motion.button)`${userButtonCSS}`
const UserButtonStyledDiv = styled(motion.div)`${userButtonCSS}`
const UserButtonStyledInput = styled(motion.input)`${userButtonCSS}`

const userButtonStyle: MotionStyle = {
    color: "black",

    fontFamily: "Faktum Regular",

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    boxSizing: "border-box",
    gap: 10,

    padding: "12px 24px 12px 24px",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    border: 0,
    margin: "0 12px 12px 0px",

    cursor: "pointer",
}

const selectedUserButtonStyle: MotionStyle = {
    ...userButtonStyle,
    cursor: "default",
    backgroundColor: "#353a77",
    color: "white",
}

const userButtonSegmentStyle: MotionStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    border: 0,
    whiteSpace: "nowrap",
}

const userButtonCircleStyle: MotionStyle = {
    ...userButtonSegmentStyle,
    width: "24px",
    height: "24px",

    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",

    borderRadius: "100%",
    color: "black",
    cursor: "pointer",
}

const UserButtonIconContainer = styled(motion.div)`
    display: flex;
    margin: auto;    
`

const userButtonInputStyle: MotionStyle = {
    ...userButtonSegmentStyle,
    backgroundColor: "white",
    border: 0,
    padding: "0 13px 0 13px",
    borderRadius: "64px",
    margin: "0 10px 0 10px",
}

const VoterContainer = styled(motion.div)<{
    smallVersion: boolean
    voteBarWidth: number
}>`
    font-family: Faktum Semibold;
    color: black;
    font-size: 20px;
    margin: 40px 0 40px 0;

    ${(props) =>
        props.smallVersion
            ? css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: ${props.voteBarWidth}px;
    row-gap: 0;
        `
            : css`
    display: grid;
    grid-template-columns: [left] 1fr [center] ${props.voteBarWidth}px [right] 1fr;
    row-gap: 24px;
        `}
`

const VoterColLeft = styled(motion.div)<{
    smallVersion: boolean
    voteBarWidth: number
}>`
    justify-self: end;

    ${(props) =>
        props.smallVersion
            ? css`
    width: ${props.voteBarWidth / 2}px;
    padding: 0;
        `
            : css`
    grid-column: left;
    padding: 10px 33px 0 0;
        `}
`

const VoterColCenter = styled(motion.div)<{
    smallVersion: boolean
}>`
    
    ${(props) =>
        props.smallVersion
            ? css`
    margin-bottom: 15px;
        `
            : css`
    grid-column: center;
        `}
`

const VoterColRight = styled(motion.div)<{
    smallVersion: boolean
    voteBarWidth: number
}>`
    
    ${(props) =>
        props.smallVersion
            ? css`
    width: ${props.voteBarWidth / 2}px;
    padding: 0;
    text-align: right;
        `
            : css`
    grid-column: right;
    padding: 10px 0 0 33px;

        `}
`

const voteEllipseStyle: MotionStyle = {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    display: "block",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
    aspectRatio: "1 / 1",
    left: "-8px",
    position: "absolute",
    borderRadius: "100%",
}

const voteReadOnlyEllipseStyle: MotionStyle = {
    ...voteEllipseStyle,
    width: "16px",
    height: "16px",
    left: "-1px",
    cursor: "default",
    backgroundColor: "black",
    boxShadow: "none",
    opacity: "100%",
}

const actionButtonStyle: MotionStyle = {
    fontFamily: "Faktum Medium",
    color: "white",
    fontSize: "20px",
    lineHeight: "110%",
    display: "inline-flex",
    padding: "14px 24px 14px 24px",
    backgroundColor: "#004751",
    borderRadius: "50px",
    border: 0,
    margin: "12px",

    cursor: "pointer",
}
